<template>
  <div>
    <!-------------------------------->
    <!-------- Barra Superior -------->
    <!-------------------------------->
    <b-navbar toggleable="md" type="dark" variant="primary" ref="nb-1">
      <b-navbar-brand href="#" @click="toggleSidebar = !toggleSidebar" class="igs-ml-10">iAlmacén</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse2"></b-navbar-toggle>

      <b-collapse id="nav-collapse2" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#" title="Inicio (Ctrl+Shift+0)"><span v-shortkey="['ctrl','shift','0']" @shortkey="p60OpcionMenu({c:'/00001', d:''},null)" @click="p60OpcionMenu({c:'/00001', d:''},null)">0.Inicio</span></b-nav-item>

          <b-nav-item-dropdown
            id="my-nav-dropdown-0"
            text="1.Mapa de Almacén"
            toggle-class="nav-link-custom"
            right
          >
            <b-dropdown-header>1. MAPA</b-dropdown-header>
            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-group id="my-nav-dropdown-11" header="1.1 Diseño Mapa">
              <b-dropdown-item><router-link to="/11100"><span v-shortkey="['ctrl','shift','1']" @shortkey="p60OpcionMenu({c:'/11100', d:'Mapa Almacén'},null)" @click="p60OpcionMenu({c:'/11100', d:'Mapa Almacén'},null)">1.1.1-Editar Mapa Almacén</span></router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/11200">1.1.2 Consultar Mapa Almacén</router-link></b-dropdown-item>
            </b-dropdown-group>

            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-header>2. ARTÍCULOS</b-dropdown-header>
            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-group id="my-nav-dropdown-12" header="1.2 Búscar Artículos">
              <b-dropdown-item><router-link to="/12100">1.2.1 Búsqueda General</router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/12200">1.2.2 Búscar Caducados</router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/12300">1.2.3 Búscar Muertos de Almacén</router-link></b-dropdown-item>
            </b-dropdown-group>
          </b-nav-item-dropdown>

          <b-nav-item href="#"><span v-shortkey="['ctrl','shift','2']" @shortkey="p60OpcionMenu({c:'/20000', d:'Entradas'},null)" @click="p60OpcionMenu({c:'/20000', d:'Entradas'},null)">2.Entradas</span></b-nav-item>

          <!-- <b-nav-item href="#"><span v-shortkey="['ctrl','shift','3']" @shortkey="p60OpcionMenu({c:'/30000', d:'Salidas'},null)" @click="p60OpcionMenu({c:'/30000', d:'Entradas'},null)">3.Salidas</span></b-nav-item> -->

          <b-nav-item-dropdown
            id="my-nav-dropdown-0"
            text="3. Salidas"
            toggle-class="nav-link-custom"
            right
          >

            <b-dropdown-group id="my-nav-dropdown-11" header="3.1 Pre-Cargas">
              <b-dropdown-item><router-link to="/31100">3.1.1 Balanceo de Cargas</router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/31200"><span v-shortkey="['ctrl','shift','c']" @shortkey="p60OpcionMenu({c:'/31200', d:''},null)" @click="p60OpcionMenu({c:'/31200', d:''},null)">3.1.2 Preparación Cargas x Palé&nbsp;&nbsp;&nbsp;<code>(Ctrl+Shift+<b>c</b>)</code></span></router-link></b-dropdown-item>
            </b-dropdown-group>

            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-group id="my-nav-dropdown-12" header="3.2 Post-Cargas">
              <b-dropdown-item><router-link to="/32100">3.2.1 Revisión de Cargas</router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/32200">3.2.2 Confirmar al Reparto</router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/32300">3.2.3 Imprimir Documentos</router-link></b-dropdown-item>
            </b-dropdown-group>

            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-group id="my-nav-dropdown-12" header="3.3 Post-Reparto">
              <b-dropdown-item><router-link to="/32100">3.3.1 Retornos x Camión</router-link></b-dropdown-item>
            </b-dropdown-group>
          </b-nav-item-dropdown>


          <b-nav-item href="#"><span v-shortkey="['ctrl','shift','4']" @shortkey="p60OpcionMenu({c:'/40000', d:'Recuentos'},null)" @click="p60OpcionMenu({c:'/40000', d:'Entradas'},null)">4.Recuentos</span></b-nav-item>

          <b-nav-item-dropdown
            id="my-nav-dropdown-0"
            text="5. Consultas"
            toggle-class="nav-link-custom"
            right
          >

            <b-dropdown-group id="my-nav-dropdown-11" header="5.1 Movimientos">
              <b-dropdown-item><router-link to="/51100"><span v-shortkey="['ctrl','shift','8']" @shortkey="p60OpcionMenu({c:'/51100', d:''},null)" @click="p60OpcionMenu({c:'/51100', d:''},null)">5.1.1 Consulta Entradas y Salidas&nbsp;&nbsp;<code>(Ctrl+Shift+<b>8</b>)</code></span></router-link></b-dropdown-item>
              <b-dropdown-item><router-link to="/51200">5.1.2 -- Uso Futuro --</router-link></b-dropdown-item>
            </b-dropdown-group>

          </b-nav-item-dropdown>

        </b-navbar-nav>

        <b-navbar-nav class="ms-auto">
          <b-nav-form>
           <multiselect v-model="opcionSeleccionada" :options="opcionesMenu" key="c" track-by="c" label="d" 
                        placeholder="Buscar Opción" deselectLabel=" " selectLabel=" " selectedLabel=" " required="false"
                        @select="p60OpcionMenu">
             <span slot="noResult">Sin resultados</span>
           </multiselect>
          </b-nav-form>
        </b-navbar-nav>

        <b-navbar-nav class="ms-auto">
          <b-nav-item-dropdown right class="px-4">
            <template v-slot:button-content>
              <em>{{$store.state.strDescripcionDistribuidor}}&nbsp;</em>
            </template>

            <b-dropdown-item v-for="i in $store.state.strListaDistribuidoresSeleccionables" :key="i.value" :value="i.value" @click="$store.commit('p10StrDistribuidor', i.value);$store.commit('p10StrDescripcionDistribuidor', i.label);">
               {{ i.label }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <b-navbar-nav class="ms-auto">
          <b-nav-item-dropdown right class="px-4">
            <template v-slot:button-content>
              <em>César</em>
            </template>
            <b-dropdown-item href="#">César</b-dropdown-item>
            <b-dropdown-item href="#">Ángel</b-dropdown-item>
            <b-dropdown-item href="#">Mary</b-dropdown-item>
            <b-dropdown-item href="#">Carlos</b-dropdown-item>
            <b-dropdown-item href="#">
              <hr />
            </b-dropdown-item>
            <b-dropdown-item href="/">Salir</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>



    <!-------------------------------->
    <!---------- BreadCrumb ---------->
    <!-------------------------------->
    <b-breadcrumb :items="$store.state.strMenuBC" style="padding-left:10px; margin-bottom:0rem"></b-breadcrumb>



    <!-------------------------------->
    <!-------- Barra Inferior -------->
    <!-------------------------------->
    <b-navbar
      toggleable="lg"
      type="dark"
      fixed="bottom"
      variant="warning"
      ref="nb-2"
      v-if="nb2Mostrar"
    >
      <b-navbar-brand href="#" @click="nb2Mostrar = false">
        <small>(c) iProgram Global Software</small>
      </b-navbar-brand>

      <b-navbar-nav>
        <b-nav-item href="#">Versión 0.1</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ms-auto">
        <b-nav-item-dropdown dropup right no-caret>
          <template v-slot:button-content>
            <b-button @click="divFecha11 = true" variant="light" size="sm">{{f92Date2DMA(ldFechaHoy)}}</b-button>
          </template>
          <div v-if="divFecha11" ref="divfecha11" class="text-center" style="position:relative; top:-160px; border: 2px solid black; background: white;">
            <b-dropdown-form href="#">
              <div class="bg-primary text-light text-center">Fecha de Trabajo</div>
              <b-card>
                <b-form-input id="fechaTrabajo" type="date" v-model="ltFechaHoy" @change="lp60ActualizarFecha()"></b-form-input>
              </b-card>
              <b-button
                class="mt-2"
                variant="secondary"
                @click="divFecha11 = false"
              >Confirmar</b-button>
            </b-dropdown-form>
          </div>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav class="ms-auto">
         <!--
        <b-nav-item-dropdown dropup right>
          <template v-slot:button-content>
            <b-navbar-brand>
              <small>Empresa ABC, S.L.</small>
            </b-navbar-brand>
          </template>
          <b-dropdown-item href="#">Empresa ABC, S.L.</b-dropdown-item>
          <b-dropdown-item href="#">Transportes XYZ</b-dropdown-item>
        </b-nav-item-dropdown>
        -->
      </b-navbar-nav>
    </b-navbar>

      <div class="sidenav-container">
        <div v-if="toggleSidebar" class="backdrop"></div>
        
        <transition name="slide-side">
          <div v-if="toggleSidebar" class="sidenav bg-light">
            Algunas opciones "ocultas" ... o no tanto ;-)
            <br />
            <div class="mt-3">
              <button class="btn-outline-primary ml-2">Impresoras</button>
              <button class="btn-outline-secondary ml-2">Nivel de Acceso</button>
              <button class="btn-outline-success ml-2">Cambio de Fecha de Trabajo</button>
              <button class="btn-outline-danger ml-2">Comandos Especiales</button>
            </div>
            <div class="mt-3">
              <button class="btn-outline-warning ml-2">Ya veremos</button>
              <button class="btn-outline-info ml-2">Algo Nuevo</button>
              <button class="btn-outline-light text-dark ml-2">¿ Sí ó No ?</button>
              <button class="btn-outline-dark ml-2">Algo se nos ocurrirá</button>
            </div>

            <div class="mt-5">
              <b-button variant="outline-danger" pill @click="toggleSidebar = !toggleSidebar">Cerrar</b-button>
              <br />
              <br />
              <br />
              <hr />
            </div>
          </div>
        </transition>
      </div>
    

  </div>

</template>

<script>
import igs_mixin from '@/igs-mixins/igs_mixin'
import Multiselect from 'vue-multiselect'

export default {
  name: 'MNC10000',
  components: { Multiselect },
  mixins:     [ igs_mixin ],  
  data() {
    return {
      itemsBC: [
        {
          text: "2. Artículos y Almacén",
          href: "#"
        },
        {
          text: " 2.4 Artículos",
          href: "#",
          to: "/10000"
        },
        {
          text: " 2.4.2 Modificación de Artículos",
          active: true
        }
      ],
      ldFechaHoy : null,
      ltFechaHoy : '',
      toggleSidebar: false,
      collapse1V: false,
      divFecha11: false,
      nb2Mostrar: true,
      opcionSeleccionada: null,
      opcionesMenu: [
                  {
                    c: "000001",
                    d: "0-Inicio"
                  },
                  {
                    c: "11100",
                    d: "1-Mapa"
                  },
                  {
                    c: "20000",
                    d: "2-Entradas"
                  },
                  {
                    c: "30000",
                    d: "3-Salidas"
                  },
                  {
                    c: "40000",
                    d: "4-Recuentos"
                  },
                  {
                    c: "50000",
                    d: "5-Consultas"
                  }
                ]
    };
  },
  methods: {

    //-- Al escoger ua opción del Menú DropDown
    // eslint-disable-next-line no-unused-vars
    p60OpcionMenu(opcionSeleccionada,id) {
      //console.dir(opcionSeleccionada);
      //console.log(id);
      this.$router.push(opcionSeleccionada.c);
      this.opcionSeleccionada = null;
    },

    //-- Al cambiar la Fecha de Trabajo
    lp60ActualizarFecha() {
      this.ldFechaHoy = this.f92A_M_D2Date(this.ltFechaHoy);
    },

    //-- ...
    // eslint-disable-next-line no-unused-vars
    getSelectedItem: function(myarg) { 
      //console.log(myarg);
    }

  },
  
  //----------------------------
  //-- Al cargar esta página
  //----------------------------
  mounted() {
    this.ldFechaHoy = new Date();
    this.ltFechaHoy = this.f92Date2A_M_D(this.ldFechaHoy);
  }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #f0ad4e;
    }
  }
}

.multiselect,
.multiselect__input,
.multiselect__single,
.multiselect__tags,
.multiselect__placeholder,
.multiselect__option:after,
.multiselect__content-wrapper,
.multiselect__content {
  width: 230px;
  font-family: inherit;
  font-size: 0.70rem;
  font-weight: lighter;
}

.multiselect
.multiselect__option--highlight,
.multiselect_tag {
  background-color: #ffffff;
}

</style>