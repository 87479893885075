<!--
#########################################################################################
######    Top Navigation Controller TNC10000  :  Contenido Parte Superior          ######
#########################################################################################
-->
<template>
 <span></span>
 <!--
    <div id="igs-head">
      < !--
      <pre>
        Sesión : {{ $store.state.strIDSesion }},
        Width: {{ $store.state.strScreenWidth }},
        Height: {{ $store.state.strScreenHeight }}      
      </pre>
      -- >
      < !--
      <pre>
         Lista : {{$store.state.strListaDistribuidoresSeleccionados}}
         Lista : {{$store.state.strListaDistribuidoresSeleccionables}}
         Lista : {{$store.state.strIDSesion}}
      </pre>
      -- >
    </div>
  -->
</template>



<!--
##################################
###########  Código  #############
##################################
-->
<script>
import igs_mixin from '@/igs-mixins/igs_mixin'

export default {
  mixins: [ igs_mixin ],

  //------------------------ Variables Locales  ---------------------------
  data () {
    return {
    }
  },

  //--------------------- Propiedades Calculadas  ------------------------
  computed : {
  },

  methods: {
  },


  mounted() {
  }
}
</script>


<!--
##################################
###########  Estilo  #############
##################################
-->
<style>
</style>