<template>
  <div id="app">
    <!-- Espacio para Notificaciones Snotify -->
    <vue-snotify></vue-snotify>
    
    <!-- Menú -->
    <MNC10000 v-if="$store.state.strUsuarioAutenticado"></MNC10000>

    <!-- Vista Actual -->
    <router-view />

  </div>
</template>

<script>
import MNC10000 from '@/igs-navegacion/MNC10000.vue'

export default {
  components: {
    MNC10000
  }
}
</script>

<style lang="scss">
@import "./assets/igs_styles.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

// $image-path: '@/node_modules/mdbvue/lib/img/';   // image path variable update
//@import './node_modules/mdbvue/lib/scss/mdb-free.scss'; // main mdb scss free file
//@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"); // font import

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
