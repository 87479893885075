import Vue from 'vue'

//-- Restor de Código .vue
import VueRouter from 'vue-router'
import SVC00000 from '@/igs-vistas/SVC00000.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SVC00000',
    component: SVC00000
  },
  {
    path: '/00801',
    name: 'SVC00801',
    component: () => import('@/igs-vistas/SVC00801.vue')
  },
  {
    path: '/00001',
    name: 'SVC00001',
    component: () => import('@/igs-vistas/SVC00001.vue')
  },
  {
    path: '/11100',
    name: 'SVC11100',
    component: () => import('@/igs-vistas/SVC11100.vue')
  },
  {
    path: '/11190',
    name: 'SVC11190',
    component: () => import('@/igs-vistas/SVC11190.vue')
  },
  {
    path: '/11200',
    name: 'SVC11200',
    component: () => import('@/igs-vistas/SVC11200.vue')
  },
  {
    path: '/20000',
    name: 'SVC20000',
    component: () => import('@/igs-vistas/SVC20000.vue')
  },
  {
    path: '/30000',
    name: 'SVC30000',
    component: () => import('@/igs-vistas/SVC30000.vue')
  },
  {
    path: '/31200',
    name: 'SVC31200',
    component: () => import('@/igs-vistas/SVC31200.vue')
  },
  {
    path: '/40000',
    name: 'SVC40000',
    component: () => import('@/igs-vistas/SVC40000.vue')
  },
  {
    path: '/51100',
    name: 'SVC51100',
    component: () => import('@/igs-vistas/SVC51100.vue')
  }

]

const igs_routes = new VueRouter({
  routes,
  mode: 'abstract'
  //mode: 'abstract',
  //base: process.env.BASE_URL
})

export default igs_routes
